import styled from 'styled-components';

export const Wrap = styled.div`
  height: calc(100vh - 85px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Spinner = styled.div`
  ${({ theme }) => `
    position: relative;
    border-radius: 50%;
    background-color: ${theme.colors.blue};
    width: 50px;
    height: 50px;
  `}

  &:after {
    content: '';
    border: 0px solid #fff;
    border-radius: 9999px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: loading 1000ms ease-out forwards infinite;
  }

  @keyframes loading {
    0% {
      border: 0px solid #fff;
    }

    20% {
      border: 8px solid #fff;
      width: 0%;
      height: 0%;
    }

    100% {
      border: 8px solid #fff;
      width: 100%;
      height: 100%;
    }
  }
`;
