import React from 'react';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  ${({ theme }) => `
    html {
      &.lock-scroll {
        -webkit-overflow-scrolling: auto;
        overflow: hidden;
        position: fixed;
        width: 100%;
        height: 100%;

        body {
          -webkit-overflow-scrolling: auto;
          overflow: hidden;
          position: fixed;
          width: 100%;
          height: 100%;
        }
      }
    }

    body {
      margin: 0;
      background-color: ${theme.colors.lightGrey};
      color: ${theme.colors.dark};
      font-family: "SF UI Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 16px;
    }

    img {
      max-width: 100%;
      vertical-align: middle;
    }

    h1, h2, h3 {
      font-family: "Intro", Helvetica, Arial, sans-serif;
      font-weight: 400;
      margin: 0 0 10px;
      line-height: 1.375;
    }

    h1 {
      font-size: 1.875rem;

      @media (min-width: ${theme.screens.lg}) {
        font-size: 3rem;
      }
    }

    h2 {
      font-size: 2.125rem;
    }

    h3 {
      font-size: 1.5rem;
    }

    h4 {
      font-family: "Intro", Helvetica, Arial, sans-serif;
      font-weight: 700;
      font-size: 1rem;
      margin: 0 0 10px;
    }

    a {
      color: ${theme.colors.dark};
      text-decoration: none;
      transition: 0.1s ease-in;

      &:hover {
        color: ${theme.colors.blue};
      }
    }

    p {
      margin-top: 0;
      line-height: 1.5;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        display: inline-block;
        position: relative;
        z-index: 1;

        &:after {
          content: '';
          position: absolute;
          bottom: 5px;
          left: 0;
          width: 100%;
          height: 4px;
          background-color: ${theme.colors.blue};
          z-index: -1;
        }

        &:hover {
          color: ${theme.colors.dark};
        }
      }
    }

    * {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      box-sizing: border-box;
    }
  `}
`;

export default GlobalStyle;
