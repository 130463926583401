import React from 'react';
import { Wrap, Spinner } from './Loader.styled';

const Loader = () => (
  <Wrap>
    <Spinner />
  </Wrap>
);

export default Loader;
