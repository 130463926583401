import React from 'react';
import MainHeader from 'components/main-header/MainHeader';
import FixedWidthLayout from 'components/_layouts/fixed-width-layout/FixedWidthLayout';
import Dashboard from 'components/dashboard/Dashboard';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import SlackAuth from 'components/auth/Auth';
import AuthWrapper from 'components/_utilities/auth-wrapper/AuthWrapper';
import { getUser } from 'redux/auth/auth.selectors';
import { useSelector } from 'react-redux';
import Orders from 'components/orders/Orders';

const AuthenticatedRoute = ({ children, ...rest }) => {
  const user = useSelector(getUser);

  return (
    <Route
      {...rest}
      render={() => user ? children : null}
    />
  );
};

const App = () => {
  return (
    <Router>
      <AuthWrapper>
        <FixedWidthLayout>
          <MainHeader></MainHeader>
          <Switch>
            <AuthenticatedRoute exact path="/">
              <Dashboard></Dashboard>
            </AuthenticatedRoute>
            <AuthenticatedRoute path="/orders">
              <Orders></Orders>
            </AuthenticatedRoute>
            <Route path="/auth">
              <SlackAuth></SlackAuth>
            </Route>
          </Switch>
        </FixedWidthLayout>
      </AuthWrapper>
    </Router>
  );
};

export default App;
