import React from 'react';
import { Wrap } from './FixedWidthLayout.styled';

const FixedWidthLayout = ({ children }) => (
  <Wrap>
    {children}
  </Wrap>
);

export default FixedWidthLayout;
