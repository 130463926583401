import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { auth, firestore } from 'config/firebase';
import { setUser } from 'redux/auth/auth.slice';
import { flattenFirebaseUser } from 'helpers/auth';

const AuthWrapper = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    auth().onAuthStateChanged(async loggedInUser => {
      if (!loggedInUser) {
        dispatch(setUser(null));
        window.sessionStorage.removeItem('idToken');
        return null;
      }

      const idTokenResult = await auth().currentUser.getIdTokenResult();
      window.sessionStorage.setItem('idToken', idTokenResult.token);

      const profileDoc = await firestore()
        .doc(`users/${loggedInUser.uid}`)
        .get();

      dispatch(
        setUser(
          flattenFirebaseUser({
            ...loggedInUser,
            ...profileDoc.data(),
            role: idTokenResult.claims.admin ? 'admin' : 'user',
          })
        )
      );
    });
  }, []);

  return children;
};

export default AuthWrapper;
