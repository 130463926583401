import styled from 'styled-components';

export const Wrap = styled.div`
  ${({ theme }) => `
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;

    @media (min-width: ${theme.screens.xl}) {
      height: 85px;
    }
  `}
`;

export const Inner = styled.div`
  ${({ theme, isLogo }) => `
    padding: 1.5rem;

    @media (min-width: ${theme.screens.xl}) {
      padding: 1.5rem 2.5rem;

      ${
        isLogo
          ? `
        position: absolute;
        top: 0;
        left: 0;
        height: 85px;
      `
          : ``
      }
    }
  `}
`;

export const Ctas = styled(Inner)`
  display: hidden;

  ${({ theme }) => `
    @media (min-width: ${theme.screens.xl}) {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 85px;
    }
  `}
`;

export const User = styled(Inner)`
  display: hidden;

  ${({ theme }) => `
    @media (min-width: ${theme.screens.xl}) {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 85px;
      display: flex;
      align-items: center;
    }
  `}
`;

export const Logo = styled.img`
  height: 2rem;
  width: auto;
  vertical-align: midde;
`;
