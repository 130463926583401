import React from 'react';
import { Link } from 'react-router-dom';
import { Pane, Text, Heading, BankAccountIcon } from 'evergreen-ui';

const Dashboard = () => (
  <Pane display="flex" paddingX="1.25rem">
    <Link to="/orders">
      <Pane
        elevation={1}
        hoverElevation={2}
        width="100%"
        margin="1.25rem"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        background="white"
        border="muted"
        padding={30}
        cursor="pointer"
      >
        <BankAccountIcon color="#EEB6D4" marginBottom={20} size={30}></BankAccountIcon>
        <Heading size={600} marginBottom={5}>Orders</Heading>
        <Text size={300}>Manage orders and reconcile payments</Text>
      </Pane>
    </Link>
  </Pane>
);

export default Dashboard;
