import React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/auth/auth.selectors';
import UserMenu from 'components/_partials/user-menu/UserMenu';
import {
  Wrap,
  Logo,
  Inner,
  Ctas,
  User,
} from './MainHeader.styled';
import SignInWithSlack from 'components/_partials/sign-in-with-slack/SignInWithSlack';

const Header = () => {
  const user = useSelector(getUser);

  return (
    <>
      <Wrap>
        <Inner isLogo>
          <a href="/">
            <Logo src="https://a.storyblok.com/f/79570/302x64/0779d29aca/mcr_codes_cmyk_trim.png" alt="Manchester Codes Admin" />
          </a>
        </Inner>
        {!user ? (
          <Ctas>
            <SignInWithSlack></SignInWithSlack>
          </Ctas>
        ) : (
          <User>
            <UserMenu />
          </User>
        )}
      </Wrap>
    </>
  );
};

export default Header;
