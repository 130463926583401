import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Loader from 'components/_partials/loader/Loader';
import { auth } from 'config/firebase';
import useQuery from 'hooks/useQuery';
import { toaster } from 'evergreen-ui';

const SlackAuth = () => {
  const query = useQuery();
  const history = useHistory();
  const state = query.get('state');
  const code = query.get('code');

  console.log(state);

  useEffect(() => {
    const doSignIn = async () => {
      try {  
        const {
          data: { token },
        } = await axios.post('https://us-central1-avocado-on-toast.cloudfunctions.net/slackAdminAuth', { code });

        await auth().signInWithCustomToken(token);

        history.push(window.atob(state) || '/');
      } catch (ex) {
        console.error(ex);
        toaster.danger("Unauthorised.");
      }
    }

    doSignIn();
  }, []);

  return <Loader />;
};

export default SlackAuth;
