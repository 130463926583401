import React, { useEffect, useState } from 'react';
import {
  Pane, Heading, Button, Table, TickCircleIcon, Badge, SideSheet, Paragraph, Tab, Card, Tablist, Strong, Text,
} from 'evergreen-ui';
import moment from 'moment';
import axios from 'axios';
import firestore from 'config/firebase';

const courses = {
  Ovd5oOCI89LGwMlu1ThU: {
    name: 'FAST',
    color: 'yellow',
  },
  JqaR140v0XOrJpkRndKF: {
    name: 'FLEXI',
    color: 'teal',
  },
};

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  useEffect(() => {
    const getOrders = async () => {
      const idToken = window.sessionStorage.getItem('idToken');
      console.log(idToken);

      const { data } = await axios.get(`${process.env.REACT_APP_FIREBASE_FUNCTIONS_ENDPOINT}/orders`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      setOrders(data.sort((a, b) => {
        const aName = `${a.user.customer.personal.firstName} ${a.user.customer.personal.lastName}`;
        const bName = `${b.user.customer.personal.firstName} ${b.user.customer.personal.lastName}`;

        if (aName < bName) { return -1; }
        if (aName > bName) { return 1; }
        return 0;
      }));
    };

    getOrders();
  }, []);

  console.log(orders);

  return (
    <>
      <Pane paddingX="2.5rem">
        <Pane display="flex" padding={16} background="white" borderRadius={3} marginBottom={20}>
          <Pane flex={1} alignItems="center" display="flex">
            <Heading size={600}>Orders</Heading>
          </Pane>
          <Pane />
        </Pane>
        <Table background="white" borderRadius={3}>
          <Table.Head>
            <Table.SearchHeaderCell />
            <Table.TextHeaderCell>
              Course
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>
              Date
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>
              Amount
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>
              Finance
            </Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {orders.map((order) => (
              <Table.Row key={order.id} isSelectable onSelect={() => setSelectedOrder(order)}>
                <Table.TextCell>
                  {order.user.customer.personal.firstName}
                  {' '}
                  {order.user.customer.personal.lastName}
                </Table.TextCell>
                <Table.TextCell>
                  {courses[order.course] ? (
                    <Badge color={courses[order.course].color}>{courses[order.course].name}</Badge>
                  ) : 'N/A'}
                </Table.TextCell>
                <Table.TextCell>{moment(order.date).format('Do MMMM YYYY HH:mm')}</Table.TextCell>
                <Table.TextCell isNumber>
                  {order.payment.balance}
                </Table.TextCell>
                <Table.TextCell isNumber>
                  {order.payment.finance ? <TickCircleIcon color="green" /> : null}
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Pane>
      <SideSheet
        isShown={Boolean(selectedOrder)}
        onCloseComplete={() => setSelectedOrder(null)}
        containerProps={{
          display: 'flex',
          flex: '1',
          flexDirection: 'column',
        }}
      >
        {selectedOrder ? (
          <>
            <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
              <Pane padding={16} borderBottom="muted">
                <Heading size={600}>
                  {selectedOrder.user.customer.personal.title}
                  {' '}
                  {selectedOrder.user.customer.personal.firstName}
                  {' '}
                  {selectedOrder.user.customer.personal.lastName}
                </Heading>
                <Paragraph size={400} color="muted">
                  {selectedOrder.user.customer.personal.email}
                </Paragraph>
              </Pane>
              <Pane display="flex" padding={8}>
                <Tablist>
                  {['Details', 'Payments'].map(
                    (tab, index) => (
                      <Tab
                        key={tab}
                        isSelected={selectedTabIndex === index}
                        onSelect={() => setSelectedTabIndex(index)}
                      >
                        {tab}
                      </Tab>
                    ),
                  )}
                </Tablist>
              </Pane>
            </Pane>
            <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>
              <Card
                backgroundColor="white"
                elevation={0}
                padding="1.25rem"
                marginBottom="0.5rem"
              >
                <Text>
                  <Strong>Address</Strong>
                  <br />
                  {`${selectedOrder.user.customer.personal.address.line1}, ${selectedOrder.user.customer.personal.address.line2}, ${selectedOrder.user.customer.personal.address.city}, ${selectedOrder.user.customer.personal.address.county}, ${selectedOrder.user.customer.personal.address.postcode}`.replace(', ,', '')}
                </Text>
              </Card>
              <Card
                backgroundColor="white"
                elevation={0}
                padding="1.25rem"
                marginBottom="0.5rem"
              >
                <Text>
                  <Strong>Phone</Strong>
                  <br />
                  {selectedOrder.user.customer.personal.phone}
                </Text>
              </Card>
              <Card
                backgroundColor="white"
                elevation={0}
                padding="1.25rem"
                marginBottom="0.5rem"
              >
                <Text>
                  <Strong>Balance</Strong>
                  {' '}
                  {selectedOrder.payment.balance}
                  <br />
                  <Strong>Payment Method</Strong>
                  {' '}
                  <Badge>{selectedOrder.payment.paymentMethod.toUpperCase()}</Badge>
                  <br />
                  <Strong>Finance</Strong>
                  {' '}
                  {selectedOrder.payment.finance ? <TickCircleIcon color="green" /> : null}
                  {selectedOrder.payment.finance ? (
                    <>
                      <br />
                      <Strong>Deposit</Strong>
                      {' '}
                      {selectedOrder.payment.financeDepositAmount}
                      <br />
                      <Strong>Finance Amount</Strong>
                      {' '}
                      {selectedOrder.payment.financeBorrowingAmount}
                      <br />
                      <Strong>Instalments</Strong>
                      {' '}
                      {selectedOrder.payment.financeInstalments}
                    </>
                  ) : null}
                </Text>
              </Card>
            </Pane>
          </>
        ) : null}
      </SideSheet>
    </>
  );
};

export default Orders;
