import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Pane, Avatar, Popover, Position, Menu, Button, MoreIcon } from 'evergreen-ui';
import { getUser } from 'redux/auth/auth.selectors';
import { auth } from 'config/firebase';

const UserMenu = () => {
  const user = useSelector(getUser);

  const handleLogout = async () => {
    await auth().signOut();
  };

  return (
    <Pane display="flex" alignItems="center">
      <Avatar name={user.name} src={user.avatar} size={40} marginRight={20} />
      <Popover
        position={Position.BOTTOM_RIGHT}
        content={
          <Menu>
            <Menu.Group>
              <Menu.Item
                onSelect={handleLogout}
                icon="log-out"
              >
                Logout
              </Menu.Item>
            </Menu.Group>
          </Menu>
        }
      >
        <Button appearance="minimal" height={24}><MoreIcon></MoreIcon></Button>
      </Popover>
    </Pane>
  );
};

export default UserMenu;
