import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    loading: true,
    forms: {
      forgotPassword: {
        submitted: false,
      },
      changePassword: {
        submitted: false,
      },
    },
  },
  reducers: {
    doLogin() {},
    createAccount() {},
    setUser(state, { payload }) {
      state.loading = false;
      state.user = payload;
    },
    sendEmailVerification() {},
    doEmailVerification() {},
    doRequestPasswordReset() {},
    passwordResetRequestSent(state) {
      state.forms.forgotPassword.submitted = true;
    },
    doChangePassword() {},
    passwordResetSuccessful(state) {
      state.forms.changePassword.submitted = true;
    },
    doEditProfile() {},
  },
});

export const { actions, reducer } = authSlice;
export const {
  createAccount,
  setUser,
  sendEmailVerification,
  doEmailVerification,
  doLogin,
  doRequestPasswordReset,
  passwordResetRequestSent,
  doChangePassword,
  passwordResetSuccessful,
  doEditProfile,
} = actions;
export default reducer;
