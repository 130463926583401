module.exports = {
  theme: {
    fontFamily: {
      display: ['ProximaNova', 'Helvetica', 'Arial', 'sans-serif'],
      body: ['ProximaNova', 'Helvetica', 'Arial', 'sans-serif'],
      intro: ['Intro'],
    },
    extend: {
      colors: {
        blue: '#6acce3',
        yellow: '#FFD400',
        dark: '#313E47',
        darkAlpha: '#838B91',
        lightGrey: '#F8F8F9',
        midGrey: '#e2e2e2',
        grey: '#6d6d6d',
        darkGrey: '#414141',
        red: '#F1583E',
        green: '#34A061',
      },
    },
  },
};
