import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

const Wrap = styled.a`
  ${({ theme }) => `
    display: inline-flex;
    background: white;
    padding: 0 0.5rem;
    align-items: center;
    height: 40px;
    border: 1px solid #EDF0F2;
    box-shadow: 0 0 1px rgba(67, 90, 111, 0.3), 0 2px 4px -2px rgba(67, 90, 111, 0.47);

    img {
      width: 22px;
      height: auto;
      display: inline-flex;
      margin-right: 0.5rem;
    }
  `}
`;

const SignInWithSlack = () => {
  const location = useLocation();

  return (
    <Wrap
      href={`https://slack.com/oauth/v2/authorize?user_scope=identity.basic,identity.email,identity.team,identity.avatar&state=${window.btoa(location.path ? `${location.path}${location.search || ''}` : '')}&client_id=1093354796259.1197008368163&team=manchestercodes&redirect_uri=${
        process.env.REACT_APP_MODE !== 'development' ? 'https://admin.manchestercodes.com' : 'http://localhost:3000'
      }/auth`}
    >
      <img src="/images/slack-glyph.svg" alt="Slack"></img>
      <span>
        Sign in with <strong>Slack</strong>
      </span>
    </Wrap>
  )
};

export default SignInWithSlack;
