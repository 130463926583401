export const flattenFirebaseUser = user => ({
  uid: user.uid,
  email: user.email,
  emailVerified: user.emailVerified,
  name: user.name,
  avatar: user.avatar,
  courses: user.courses,
  modules: user.modules,
  role: user.role,
  incomplete: user.incomplete,
  token: user.token,
  weeks: user.weeks,
});
